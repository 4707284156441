import React from "react";
import {
  StyleSheet,
  Text,
  Dimensions,
  ImageBackground,
  Linking,
} from "react-native";
import imageBg from "../assets/bg2.png";
import HeaderBar from "./HeaderBar";
import PageBody from "./PageBody";

function LandingPage() {
  const Footer = () => (
    <Text
      style={styles.footerTxt}
      onPress={() =>
        Linking.openURL(
          "https://drive.google.com/file/d/19gZTkE7GJLv6YFbTE4H10YH8DB5iQNii/view?usp=share_link"
        )
      }
    >
      View my resume{" "}
      <Text style={{ textDecorationLine: "underline" }}>here</Text>.
    </Text>
  );
  return (
    <ImageBackground style={styles.bgVue} source={imageBg}>
      <HeaderBar />
      <PageBody />
      <Footer />
    </ImageBackground>
  );
}

export default LandingPage;

const windowHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
  bgVue: {
    backgroundColor: "black",
    minHeight: windowHeight,
    paddingHorizontal: "8%",
    justifyContent: "space-between",
  },
  footerTxt: {
    color: "#969696",
    alignSelf: "center",
    marginVertical: 25,
    textAlign: "center",
    fontSize: 12,
  },
});
