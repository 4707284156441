import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  Pressable,
  Linking,
  Image,
} from "react-native";
import github from "../assets/github.png";
import linkedin from "../assets/linkedin.png";
import peerlist from "../assets/peerlist.png";
import meThumbUp from "../assets/meThumbUp.png";
import meSayHi from "../assets/meSayHi.png";
import resumeImg from "../assets/resumeImg.png";

function PageBody() {
  const LogoBtn = (props) => (
    <Pressable
      onPress={() => Linking.openURL(props.url)}
      style={styles.imgContainer}
    >
      <Image source={props.imageSrc} style={styles.logoImg} />
    </Pressable>
  );

  return (
    <View style={styles.parentVue}>
      <Image source={meSayHi} style={styles.meImg} />
      <Text style={styles.mainTxt}>👋 Hi, I am Parikshith.</Text>
      <Text style={styles.subTxt}>React Native mobile app developer. 👨‍💻</Text>
      <Text style={styles.ContentTxt}>
        Based out of Bangalore, I am a React Native developer with experience in
        building mobile applications with neat and clean UI UX and reusable
        components with optimal JavaScript code for both Android and iOS.
      </Text>
      <View style={styles.logoImgVue}>
        <LogoBtn url="https://github.com/parikshithv" imageSrc={github} />
        <LogoBtn
          url="https://www.linkedin.com/in/parikshith-v-964144185/"
          imageSrc={linkedin}
        />
        <LogoBtn url="https://peerlist.io/parikshithv" imageSrc={peerlist} />
        <LogoBtn
          url="https://drive.google.com/file/d/19gZTkE7GJLv6YFbTE4H10YH8DB5iQNii/view?usp=share_link"
          imageSrc={resumeImg}
        />
      </View>
    </View>
  );
}

export default PageBody;

const windowWidth = Dimensions.get("window").width;
const txtSize =
  windowWidth > 600
    ? { main: 28, sub: 16, cont: 18 }
    : { main: 20, sub: 11, cont: 14 };

const styles = StyleSheet.create({
  parentVue: {
    // backgroundColor: "grey",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 350,
  },
  mainTxt: {
    color: "white",
    fontSize: txtSize.main,
    fontWeight: 700,
    textAlign: "center",
  },
  subTxt: {
    color: "#969696",
    fontSize: txtSize.sub,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 15,
    textAlign: "center",
  },
  ContentTxt: {
    color: "white",
    fontSize: txtSize.cont,
    fontWeight: 300,
    textAlign: "center",
    maxWidth: 550,
    lineHeight: 28,
  },
  TopContainer: {
    width: "65%",
    padding: 25,
    textAlign: "center",
  },
  imgContainer: {
    marginHorizontal: 15,
  },
  logoImgVue: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 45,
  },
  logoImg: {
    height: 26,
    width: 26,
    resizeMode: "contain",
  },
  meImg: {
    height: 150,
    width: 150,
    resizeMode: "contain",
    marginBottom: 25,
    // borderWidth: 1,
    // borderColor: "#303030",
    borderRadius: 50,
    paddingRight: 25,
    backgroundColor: "rgba(255, 255, 255, 0.03)",
  },
});
