import React, { useCallback, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Dimensions,
  Linking,
} from "react-native";

function HeaderBar() {
  const [btnColors, setBtnColors] = useState({
    bg: "black",
    txt: "white",
    border: "#969696",
  });

  const windowWidth = Dimensions.get("window").width;

  // useEffect(() => {
  //   windowWidth > 600 &&
  //     setInterval(() => {
  //       changeBtnFocus(true);
  //       setTimeout(() => {
  //         changeBtnFocus(false);
  //       }, 500);
  //     }, 5000);
  // }, []);

  const changeBtnFocus = (focus) => {
    focus
      ? setBtnColors({ bg: "#f2f2f0", txt: "black", border: "black" })
      : setBtnColors({ bg: "black", txt: "white", border: "#969696" });
  };

  const WideScreen = () => (
    <View style={styles.parentVue}>
      <Text style={styles.headerTxt}>Parikshith_V</Text>
      <View style={styles.headerBtnsVue}>
        <Text style={styles.headerQTxt}>Want to know more about me?</Text>
        <Pressable
          style={[
            styles.buttonVue,
            // { backgroundColor: btnColors.bg, borderColor: btnColors.border },
          ]}
          // onHoverIn={() => changeBtnFocus(true)}
          // onHoverOut={() => changeBtnFocus(false)}
          onPress={() => Linking.openURL("mailto:hello@parikshithv.in")}
        >
          <Text style={[styles.buttonTxt, { color: btnColors.txt }]}>
            Say Hi!
          </Text>
        </Pressable>
      </View>
    </View>
  );

  const MobileScreen = () => (
    <View style={[styles.parentVue, { paddingVertical: 5 }]}>
      <Text style={[styles.headerTxt, { fontSize: 14, margin: 5 }]}>
        Parikshith_V
      </Text>
      <View style={styles.headerBtnsVue}>
        <Pressable
          style={[styles.buttonVue, styles.mobVueBtn]}
          onPress={() => Linking.openURL("mailto:hello@parikshithv.in")}
        >
          <Text style={[styles.buttonTxt, { fontSize: 14 }]}>📩</Text>
        </Pressable>
      </View>
    </View>
  );

  const FinalRender = useCallback(
    () => (windowWidth > 600 ? <WideScreen /> : <MobileScreen />),
    [windowWidth, btnColors]
  );

  return <FinalRender />;
}

export default HeaderBar;

const styles = StyleSheet.create({
  parentVue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 15,
    paddingVertical: 8,
    marginVertical: 35,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#969696",
    alignItems: "center",
    backgroundColor: "black",
  },
  headerTxt: {
    fontSize: 18,
    fontWeight: 700,
    color: "white",
    margin: 12,
  },
  buttonVue: {
    borderColor: "#D8D8D8",
    borderWidth: 1,
    justifyContent: "center",
    paddingHorizontal: 25,
    paddingVertical: 8,
    borderRadius: 8,
    alignItems: "center",
  },
  buttonTxt: {
    color: "white",
    fontSize: 16,
    fontWeight: 500,
  },
  headerQTxt: {
    fontSize: 16,
    fontWeight: 200,
    color: "white",
    margin: 12,
  },
  headerBtnsVue: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  mobVueBtn: {
    paddingHorizontal: 16,
    paddingVertical: 5,
    marginVertical: 2,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
});
